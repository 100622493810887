.banner-img-sm {
  height: 75vh;
  background-position: 0%;
}

.slide-1-content-sm {
  position: absolute;
  bottom: 10%;

  width: 100%;
}

.modal-video {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 10%;
  height: 50%;
  width: 80%;
}
.main-banner-img {
  display: block !important;
}
.slide-1-content-sm p {
  font-size: 0.7em;
  color: white;
  text-align: center;
  margin-bottom: 5px;
}
.slide-1-content-sm h1 {
  font-size: 2em;
  color: white;
  font-weight: 900;
  text-align: center;
}

.slide-1-content-sm button {
  font-size: 14px;

  color: #1d1d1d;
  background: #f8f4f1;
  border: none;
  font-weight: 600;
  border: none;
  display: block;
  margin: auto;
  margin-top: 1.8em;
  padding: 0.4em 2.5em;
}
.home-bike-card-color {
  scale: 0.75;
}

@media only screen and (max-width: 700px) {
  .new-banner-cta-right-video {
    z-index: 3;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* translate: 50% -50%; */
  }
  .heading-homepage {
    font-size: 1.3em;
  }
  .faq-heading {
    font-size: 1em;
    width: 95%;
  }
  .gif-btn {
    font-size: 0.7em;
  }
  .range-card-heading {
    font-size: 1.5em;
  }
  .range-card-sub-div-content {
    max-width: 100%;
  }
  .desk-gif-1 {
    bottom: 5% !important;
    left: 3% !important;
    /* transform: translate(-50%, -50%); */
  }

  .desk-gif-2 {
    bottom: 10% !important;
    left: 6% !important;
    /* transform: translate(-50%, -50%); */
  }

  .desk-gif-3 {
    bottom: 10% !important;
    left: 6% !important;
    /* transform: translate(-50%, -50%); */
  }

  .mobile-gif-heading {
    font-size: 1.5em;
  }
  .mobile-gif-heading {
    font-size: 0.9em;
  }

  .gif-content {
    font-size: 0.8em;
  }
  .know-btn {
    padding: 0.5rem 0.8em !important;
  }
  .em-btn {
    padding: 0.5rem 0.8em !important;
  }
  .play-icon-container {
    position: absolute;
    top: 88% !important;
    right: 38% !important;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .home-bike-card-color {
    scale: 0.7;
  }

  .celebs-heading {
    position: absolute;
    top: 45%;
  }
  .blogs-card {
    padding: 1em;
    padding-bottom: 1em;
  }
  .specs-highlight h3 {
    font-size: 1.1em;
  }

  .spec-box-div {
    width: 100%;
  }

  .home-cta-2 {
    background-color: #004dac;
    color: #f0f1f5;
    outline: 0px;
    border: 0px;
    font-size: 0.8em;
  }
  .specs-highlight p {
    font-size: 0.9em;
    color: #666664;
  }

  .specs-highlight button {
    font-size: 0.8em;
    background-color: #004dac;
  }

  .specs-highlight br {
    display: none;
  }

  .hidden-on-phone {
    display: none;
  }
  .banner-video {
    width: 100%;
    height: 80vh;
  }

  .home-cta-showstopper-btn {
    position: absolute;
    bottom: 2%;
    left: 50%;
    translate: -50% -50%;
  }
  .new-cta {
    position: absolute;
    bottom: 10%;
    transform: translate(-50%, -50%);
  }

  .btn-container {
    position: absolute;
    top: 83%;
    left: 27.2%;
    translate: translate(-50%, -50%);
    z-index: 2;
  }

  .btn-container-newlaunch {
    position: absolute;
    top: 83%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .btn-container-productdiv {
    position: absolute;
    top: 104%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .landing-cta {
    background-color: #004dac;
    color: white;
    outline: 0px;
    border: 0px;
    font-size: 1.1em;
    padding: 0.4em 2.5em;
    border-radius: 25px;
  }

  .newlaunch-doodle-cta {
    white-space: nowrap;
    background-color: #004dac;
    color: white;
    outline: 0px;
    border: 0px;
    font-size: 1em;
    padding: 0.4em 1.3em;
    border-radius: 25px;
  }

  .logo-img {
    width: 250px;
  }
  .best-seller img {
    scale: 1;
    transform: translate(0%);
  }

  .best-seller-text {
    width: 100%;
  }
  .best-seller-text-subHeading {
    font-size: 15px;
    color: #bbbbbb;
    margin: 0px;
    text-align: center;
  }

  .best-seller-text h1 {
    text-align: center;
    padding-top: 0px;
    font-size: 65px;
    color: #000000;
    font-weight: 900;
    margin-top: 0em;
  }

  .best-seller-text-desc {
    font-size: 13px;
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .best-seller-text button {
    font-size: 13px;
    color: white;
    background-color: #000000;
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 2em;
    border: none;
    padding: 0.7em;
  }
  /* .................................. */

  .bike-card {
    /* background-color: #f8f4f1; */
    border: none;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
  .bike-card h5 {
    font-weight: bold;
    font-size: 1.5em;
  }
  .the-bikes .bike-img-bg {
    /* aspect-ratio: 1/1; */

    align-items: center;
    /* width: 75%;
        height: 75%; */
  }

  .card-points p {
    font-size: 0.9em;
  }

  .card-price {
    font-weight: bold;
    font-size: 1.4em;
    gap: 1em;
  }
  .card-price span {
    color: #7c7676b7;
    font-weight: 550;
    font-size: 1.45em;
    text-decoration: line-through;
  }

  /* .bike-card button {
    font-size: 0.9em;
    background-color: #000000;
    color: white;
    border: none;
    width: 100%;
    display: block;
    margin: auto;
    padding: 0.9em;
    border: none;
    box-shadow: 0px;
  } */
  /* ........................ */
  .accessories-section div {
    background-color: #ffffff;
  }
  .accessories-section-overlay {
    position: absolute;
    top: 80%;
    left: 0%;
    width: 100%;
    translate: 0% -80%;
    background-color: transparent !important;
  }

  .accessories-section-overlay h1 {
    font-size: 2em;
    text-align: center;
  }

  .accessories-section-overlay button {
    font-size: 0.8em;
    color: white;
    background-color: rgb(0, 0, 0);
    border: none;
    width: auto;
    display: block;
    margin: auto;
    font-weight: 500;
    margin-top: 2em;
    padding: 0.5em 1.5em;
  }

  /* .................. */
  /* ........................................ */

  .testimonials {
    padding-top: 3em;
    padding-bottom: 3em;
    background-color: #ffffff;
  }
  .testimonials-cards {
    margin-top: 2em;
  }
  .testimonials h1 {
    font-weight: 900;
    text-align: center;
    font-size: 1.4em;
    /* margin-bottom: 2em; */
    color: #000000;
  }

  .testimonials h5 {
    font-size: 1.5em;
    color: #000000;
    font-weight: 900;
  }

  .testimonials-desc {
    font-size: 0.9em;
    color: #222121;
    text-align: center;
  }

  /* ........................ */

  .home-news h1 {
    font-size: 4em;
    font-weight: 900;
  }
  .home-news h2 {
    font-size: 1.4em;
    margin-top: 1em;
  }
  .home-news p {
    font-size: 0.9em;
  }
  .home-news a {
    border: none;
    border-radius: 0px;
  }

  .banner-img {
    height: 95vh;
    background-position: center;
    background-size: cover;
  }
  /* ......... */

  .range-hight-slider {
    height: 40vh;
  }

  .home-banner-cta-right {
    z-index: 3;
    top: 85%;
    right: 50%;
    translate: 50% -50%;
  }
  .home-cta {
    width: fit-content;
    font-size: 0.8em;
    padding: 0.6em 1.3em;
    font-weight: 600;
  }
  .new-banner-cta-right {
    z-index: 3;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* translate: 50% -50%; */
  }
  .event-button {
    z-index: 3;
    top: 85%;
    right: 50%;
    translate: 50% -50%;
    padding: 1vmax 2vmax !important;
    font-weight: 600;
  }

  .home-banner-cta-left {
    z-index: 3;
    top: 85%;
    left: 50%;
    translate: -50% -50%;
  }

  .our-range img {
    width: 200px;
    transform: scale(1.7);
  }

  .splide__slide.is-active img {
    /* transition: 200ms linear; */
    z-index: 50;
  }

  .splide__slide .range-cta {
    bottom: -90%;
    /* display: none; */
    opacity: 0;
    /* width: 60%; */
    left: 50%;
    translate: -50% -50%;
  }
  .splide__slide.is-active .range-cta {
    bottom: -90%;
    opacity: 1;
    /* width: 60%; */
    transition: 300ms ease-in-out;
    left: 50%;
    translate: -50% -50%;
  }

  .splide__slide.is-active .font-facing {
    width: 137px;
    /* transform: scale(4.7); */

    /* transition: 300ms ease-in-out; */
    animation: big-bike 0.4s ease-in-out;

    animation-fill-mode: forwards;
  }
  .splide__slide .silder-range-heading {
    top: -100.5%;
    display: block;
  }
  .splide__slide.is-active .silder-range-heading {
    font-size: 2em;
    position: absolute;
    display: block;

    font-weight: 900;
    width: 100%;
    white-space: nowrap;
    text-align: center;

    color: #bbbbbb;
  }

  .range-cta {
    bottom: -110% !important;
    left: 50%;
    /* width: 50% !important; */
    translate: -50%;
  }

  @keyframes big-bike {
    to {
      transform: scale(2.5);
    }
  }

  .voice-of-people {
    width: 100%;
    height: 200px;
  }
  .voice-of-people-thumbnail {
    height: 80px;
    border-radius: 5px;
  }
  .find-store-btn {
    position: absolute;
    bottom: 60%;
    left: 50%;
    translate: -50% -50%;
  }
  .home-testride-btn {
    top: 55%;
    left: 8%;
    font-size: 0.9em;
  }
}
