.blog-div:hover {
  .blog-category {
    text-decoration: underline;
  }
  .blog-heading {
    text-decoration: underline;
  }
}

.blog-category-active {
  border-bottom: 2px solid #004dac;
}

.blog-categories {
  gap: 3em;
}

.news-categories {
  gap: 3em;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.active-sort {
  background-color: #004dac;
  color: white;
}

.active-filter {
  background-color: #004dac;
  color: white;
}

/* Bottom Sheet Menu */
.bottom-sheet {
  position: fixed;
  bottom: -60%;
  left: 0;
  width: 100%;
  height: 60%;
  background: white;
  border-radius: 15px 15px 0 0;
  padding: 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease-in-out;
  z-index: 20;
}

/* Show the menu */
.bottom-sheet.show {
  bottom: 0;
}

@media screen and (max-width: 660px) {
  .blog-categories {
    gap: 1.3em;
  }
  .news-categories {
    gap: 2.6em;
  }
}
