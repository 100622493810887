@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Passion+One&display=swap"); */

@font-face {
  font-family: "Passion One";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Passion_One/PassionOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Glamatrix";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/Glamatrix.ttf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SpeedBeast";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/SpeedBeast+FREE.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Medium.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Bold.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-bolder";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-ExtraBold.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-black";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Black.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-Light";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Light.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis-Extra-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-ExtraBold.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Semi-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold-Italic";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-BoldItalic.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/font/Poppins-Thin.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Bold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueBold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Regular";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueRoman.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueMedium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Thin";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueThin.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Light";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueLight.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-Neue-Black";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/helvetica/HelveticaNeueBlack.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Italic";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-Italic.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-Medium.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-MediumItalic";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-MediumItalic.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-SemiBold.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBoldItalic";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/inter/Inter-SemiBoldItalic.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Honey-Salt";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/fonts/Honey+Salt.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

.honey-salt {
  font-family: "Honey-Salt" !important;
}
.inter-italic {
  font-family: "Inter-Italic" !important;
}
.inter-medium {
  font-family: "Inter-Medium" !important;
}
.inter-medium-italic {
  font-family: "Inter-MediumItalic" !important;
}
.inter-regular {
  font-family: "Inter-Regular" !important;
}
.inter-semi-bold {
  font-family: "Inter-SemiBold" !important;
}
.inter-semi-bold-italic {
  font-family: "Inter-SemiBoldItalic" !important;
}

.metropolis-bold {
  font-family: "Metropolis-bold" !important;
}

.metropolis-black {
  font-family: "Metropolis-black" !important;
}

.metropolis-medium {
  font-family: "Metropolis-medium" !important;
}

.metropolis-light {
  font-family: "Metropolis-light" !important;
}

.helvetica-neue-bold {
  font-family: "Helvetica-Neue-Bold" !important;
}

.helvetica-neue-regular {
  font-family: "Helvetica-Neue-Regular" !important;
}

.helvetica-neue-medium {
  font-family: "Helvetica-Neue-Medium" !important;
}

.helvetica-neue-thin {
  font-family: "Helvetica-Neue-Thin" !important;
}

.helvetica-neue-light {
  font-family: "Helvetica-Neue-Light" !important;
}

.helvetica-neue-black {
  font-family: "Helvetica-Neue-Black" !important;
}

.poppins-regular {
  font-family: "Poppins-Regular";
}

.poppins-medium {
  font-family: "Poppins-Medium";
}

.poppins-bold {
  font-family: "Poppins-Bold";
}

.poppins-semi-bold {
  font-family: "Poppins-Semi-Bold";
}

.poppins-light {
  font-family: "Poppins-Light";
}

.poppins-bold-italic {
  font-family: "Poppins-Bold-Italic";
}

.poppins-thin {
  font-family: "Poppins-Thin";
}

.service-block {
  background-color: white;
  border-radius: 15px;
}

.new-tag-navbar {
  top: 8%;
  left: 47.5%;
}

.service-block:hover {
  .service-block-btn {
    background-color: black;
    color: white;
  }
}

.service-block-btn {
  background-color: #004dac;
  color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.service-block-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.service-block-text {
  height: 50px;
}

body {
  padding: 0%;
  margin: 0%;
  font-family: "Metropolis", sans-serif;
}
.text-passion {
  font-family: "Passion One", cursive;
  font-size: 3.2em;
  text-transform: uppercase;
}

.em-btn {
  background-color: #004dac !important;
  color: #ffffff;
  padding: 1rem 2em;
  border-radius: 25px;
}

.em-btn:hover {
  background-color: #000000 !important;
  color: #ffffff;
}

.em-btn-1 {
  background-color: #004dac;
  color: #ffffff;
  padding: 0.5rem 1em;
  border-radius: 25px;
  width: 200px;
  display: inline-block;
  text-align: center;
}

.em-btn-2 {
  background-color: #ffffff;
  color: #004dac;
  padding: 0.5rem 1em;
  border-radius: 25px;
  width: 200px;
  display: inline-block;
  text-align: center;
}

.em-btn-1:hover {
  background-color: #000000;
  color: #ffffff;
}

.em-btn-2:hover {
  background-color: #000000;
  color: #ffffff;
}

.text-em {
  color: #004dac;
}
.background-em {
  background: #004dac;
}
.desktop-nav {
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(197, 197, 197);
}
.book-btn {
  background: #004dac;
}
.book-btn:hover {
  background: #000000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}

.quiz-button {
  width: 6rem;
  padding: 10px 20px;
  background: #004dac;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.quiz-button-result {
  width: 8.5rem;
  padding: 10px 20px;
  background: #004dac;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.quiz-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 650px;
  min-height: 350px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
}

.quiz-form-input {
  font-size: 1rem;
  background-color: #f9f9fa;
  border: solid 1px #e0e0e0;
  color: black;
  width: 100% !important;
  margin-bottom: 1em;
  padding: 0.8em;
  box-sizing: border-box;
}

.quiz-form-input:focus {
  outline: none;
}

.join-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: justify;
  padding: 20px;
}

.join-screen h2 {
  font-size: 2rem;
  color: #111;
}

.join-screen p {
  color: #555;
}

.progress-line {
  width: 0%;
  height: 5px;
}

.question .active {
  animation: progress 10s linear;
}

.quiz-screen .question .question-count {
  margin: 20px 0px;
  text-align: center;
  font-size: 16px;
  color: #555;
}

.quiz-screen .question .main {
  height: 360px;
  padding: 20px 40px;
}

.quiz-screen .question .main .title span {
  font-size: 16px;
  color: #555;
  font-weight: 600;
}

.quiz-screen .question .main .title p {
  margin-top: 5px;
  color: #111;
}

.quiz-screen .question .main .options {
  margin: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.quiz-screen .question .main .options .option {
  width: calc(50% - 20px);
  border: 1px solid #bbb;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-screen .question .main .options .option-active {
  background: #004dac;
  color: #fff;
  width: calc(50% - 20px);
  border: 1px solid #bbb;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-screen .question .control {
  padding: 10px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #eee;
}

.result-screen {
  padding: 2em;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.WhatsAppButton__root {
  bottom: 12% !important;
  right: 5% !important;
}

@keyframes progress {
  0% {
    width: 0%;
    background: #004dac;
  }
  70% {
    width: 70%;
    background: #004dac;
  }
  80% {
    width: 80%;
    background: #ed2939;
  }
  100% {
    width: 100%;
    background: #ed2939;
  }
}

.nav-items {
  gap: 4.2em;
}

.nav-upper-items {
  gap: 15em;
}

.dropdown-heading {
  color: rgb(1, 1, 1);
  font-size: 14px;
}
.dropdown-heading-1 {
  color: rgb(0, 0, 0);
  font-size: 14px;
}
.quiz-heading {
  color: #004dac;
  font-size: 1.5rem;
  font-weight: 600;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-heading:hover {
  color: rgb(32, 31, 31);
}
.dropdown-heading-1:hover {
  color: rgb(32, 31, 31);
}

/* .dropdown-content-1 {
	left: -40%;
} */

.dropdown-content-1 {
  width: 100%;

  display: none;
  position: absolute;
  top: 50%;
  border-radius: 5px;
  padding-top: 1.5%;
  z-index: 5;
}
.dropdown-content-1:hover {
  display: block;
}

body:has(.dropdown-heading-1:hover) .dropdown-content-1 {
  display: block;
}

.dropdown-content-2 {
  left: -20%;
}
.dropdown-content-3 {
  left: -40%;
}
.dropdown-content-4 {
  left: -25%;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;

  border-radius: 5px;

  z-index: 5;
}

.dropdown-content-x-factor {
  display: none;
  position: absolute;
  min-width: 160px;
  border-radius: 5px;
  left: 100%;
  top: 15%;
  z-index: 5;
}

.dropdown-content-desire {
  display: none;
  position: absolute;
  min-width: 160px;
  border-radius: 5px;
  left: 100%;
  top: 35%;
  z-index: 5;
}

.dropdown-content-list {
  color: white;
  background-color: #004dac;
  border-radius: 5px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-list-x-factor {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-list-desire {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-list-xcape {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-content a {
  color: #ffffff;
  display: block;
}

.dropdown-content-x-factor a {
  color: black;
  background-color: white !important;
  display: block;
}

.dropdown-content-x-factor a:hover {
  color: #004dac !important;
  text-decoration: underline;
  transition: 0.1s;
}

.dropdown-content-x-factor a {
  text-decoration: none;
}

.dropdown-content-desire a {
  color: black;
  background-color: white !important;
  display: block;
}

.dropdown-content-desire a:hover {
  color: #004dac !important;
  text-decoration: underline;
  transition: 0.1s;
}

.dropdown-content-desire a {
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #0e5bb9;
  font-family: Metropolis-Medium;
  color: #fff;
  transition: 0.1s;
}

.x-factor-category:hover .dropdown-content-x-factor {
  display: block;
}

.desire-category:hover .dropdown-content-desire {
  display: block;
}

.xcape-category:hover .dropdown-content-xcape {
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content-1 {
  display: block;
}

.book-btn {
  font-size: 13px;
  font-weight: 600;
  border-radius: 25px;
  padding: 0.7em 2em;
}

footer {
  background-color: #ededed;
  margin-top: 0em;
}

.footer-desc {
  font-size: 0.8em;
}

.footer-links {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

footer .social {
  font-size: 1.7em;
  color: #004dac;
}
footer h5 {
  font-size: 0.9em;
  font-weight: 900;
}

.footer-links a {
  color: #000000;
  font-size: 0.8em;
  text-decoration: none;
  display: block;
  margin-top: 1em;
}
.footer-bottom p {
  font-size: 0.8em;
}
.footer-bottom a {
  color: #000000;
  font-size: 0.8em;
  text-decoration: none;
}

.star-color {
  color: #e7d007;
}

.mobile-nav {
  position: sticky;
  top: 0%;
  z-index: 5;
  background-color: #ffffff;
}

/* .mobile-sidenavbar {
  height: 100vh;
  background-color: #004dac;
  color: #ffffff;
  position: sticky;
  top: 0%;
  z-index: 98;
  overflow-x: scroll;
  right: 0%;
  transition: 0.2s;
} */

.mobile-sidenavbar-formula-fun {
  height: 100vh;
  background-color: white;
  color: black;
  position: fixed;
  top: 0%;
  z-index: 98;
  overflow-x: scroll;
  right: 0%;
  transition: 0.2s;
}

.navbar-open {
  width: 100%;
}
.navbar-off {
  width: 0%;
  padding: 0%;
}

.bike-range-hover-cards .bike-nav-card-1:hover {
  animation: movebike-1 0.2s ease-in-out;
  animation-fill-mode: both;
}

.bike-range-hover-cards .bike-nav-card-1:not(:hover) {
  animation: backtonormalmovebike-1 0.2s ease-in-out;
  animation-fill-mode: both;
}
.range-banner-img {
  height: 92vh;
  background-position: auto;
  background-size: cover;

  background-position: center;
  background-repeat: no-repeat;
}

.call-wa-chat-btn-fixed-wrap {
  position: fixed;
  display: block;
  z-index: 5;
  right: 1%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(0, 77, 172);
  font-size: 1.6em;
  display: flex;

  align-items: center;
  bottom: 15%;
}

.WhatsAppButton__root img {
  width: 50px !important;
  height: 50px !important;
}

.WhatsAppButton__root .closePopUp {
  width: 12px !important;
  height: 12px !important;
}

.wa-chat-btn-fixed-wrap {
  position: fixed;
  display: block;
  z-index: 5;
  right: 1%;
  bottom: 5%;
}

.wa-chat-btn-icon-image {
  width: 50px;
  cursor: pointer;
}

/* Styling for an info pane that slides out from the left. 
   * Hidden by default. */
#panel {
  height: 100%;
  width: null;
  background-color: white;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  transition: all 0.2s ease-out;
}

.open {
  width: 250px;
}

.place {
  font-family: "open sans", arial, sans-serif;
  font-size: 1.2em;
  font-weight: 500;
  margin-block-end: 0px;
  padding-left: 18px;
  padding-right: 18px;
}

.distanceText {
  color: silver;
  font-family: "open sans", arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
  margin-block-start: 0.25em;
  padding-left: 18px;
  padding-right: 18px;
}

/* ............................... */

.voltage-button {
  position: relative;
}

.voltage-button button {
  color: rgb(0, 0, 0);
  background: #ffffff;
  padding: 01em 1.5em;
  border-radius: 40px;
  width: 190px;
  font-size: 0.8em;
  line-height: 1em;
  letter-spacing: 0.075em;
  transition: background 0.3s;
}

.voltage-button button:hover {
  cursor: pointer;
}

.voltage-button button + svg,
.voltage-button button + svg + .dots {
  opacity: 1;
}

.voltage-button svg {
  display: block;
  position: absolute;
  top: -0.55em;
  left: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 1em);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 0.1s;
}

.voltage-button svg path {
  stroke-dasharray: 100;
  filter: url("#glow");
}

.voltage-button svg path.line-1 {
  stroke: #ffffff;
  stroke-dashoffset: 0;
  animation: spark-1 4s linear infinite;
}

.voltage-button svg path.line-2 {
  stroke: hsl(113, 100%, 60%);
  stroke-dashoffset: 500;
  animation: spark-2 4s linear infinite;
}

.voltage-button .dots {
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.4s;
}

.voltage-button .dots .dot {
  width: 1rem;
  height: 1rem;
  background: white;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
}

.voltage-button .dots .dot-1 {
  top: 0;
  left: 20%;
  animation: fly-up 3s linear infinite;
}

.voltage-button .dots .dot-2 {
  top: 0;
  left: 55%;
  animation: fly-up 3s linear infinite;
  animation-delay: 0.5s;
}

.voltage-button .dots .dot-3 {
  top: 0;
  left: 80%;
  animation: fly-up 3s linear infinite;
  animation-delay: 1s;
}

.voltage-button .dots .dot-4 {
  bottom: 0;
  left: 30%;
  animation: fly-down 3s linear infinite;
  animation-delay: 2.5s;
}

.voltage-button .dots .dot-5 {
  bottom: 0;
  left: 65%;
  animation: fly-down 3s linear infinite;
  animation-delay: 1.5s;
}

@keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@keyframes fly-up {
  0% {
    opacity: 0;
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    transform: translateY(-3rem) scale(0.2);
  }
}

@keyframes fly-down {
  0% {
    opacity: 0;
    transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    transform: translateY(3rem) scale(0.2);
  }
}

@keyframes movebike-1 {
  from {
    left: -20%;
  }
  to {
    left: -10%;
  }
}
@keyframes backtonormalmovebike-1 {
  from {
    left: -10%;
  }
  to {
    left: -20%;
  }
}

/* Styling for Autocomplete search bar */
#pac-card {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-family: Roboto;
  margin: 10px 10px 0 0;
  width: 400px;
  position: absolute;
  right: 0.5%;
  top: 9%;
  z-index: 5;
  -moz-box-sizing: border-box;
  outline: none;
}

.directions-link:hover {
  color: #004dac;
}

#toggle-buttons .btn {
  font-weight: normal;
  background-color: #ffffff; /* Set the background color */
}

#toggle-buttons .btn.active {
  background-color: #004dac;
  color: #f0f1f5; /* Set the highlighted background color */
}

.directions-link {
  text-decoration: none;
  font-size: 1.1rem;
  color: #004dac;
}

#store-div-list {
  padding: 2em;
  border: solid 1px #e2e2e2;
  margin-bottom: 2em;
  /* box-sizing: border-box; */
  /* -moz-box-sizing: border-box; */
}

#store-container {
  padding: 3% 10% 3% 10%;
}

#pac-card-new {
  overflow: hidden;
  box-sizing: border-box;
  margin: 10px 10px 0 0;
  width: 100%;
  -moz-box-sizing: border-box;
  outline: none;
}

.find-store-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#map {
  height: 80vh;
  width: 100%;
}

#pac-container {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 12px;
}

#pac-container-new {
  padding-top: 12px;
  padding-bottom: 12px;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin: auto;
  border: 1px solid grey;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  text-overflow: ellipsis;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;

  background-color: #000000;
  font-size: 18px;
  font-weight: 400;
  padding: 15px;
}

.hidden {
  display: none;
}

.WhatsAppButton__root {
  bottom: 6% !important;
  right: 1% !important;
}

/* ..................... 0*/

@media screen and (max-width: 1550px) {
  .book-btn {
    font-size: 11px;
    font-weight: 600;
    border-radius: 25px;
    padding: 0.7em 1.5em;
  }

  .nav-upper-items {
    gap: 7.1em;
  }
  .nav-items {
    gap: 3em;
  }
  .desktop-nav p {
    font-size: 0.77em;
  }
  .desktop-nav .dropdown-heading {
    font-size: 0.77em;
  }
}

@media screen and (max-width: 1500px) {
  .nav-upper-items {
    gap: 7em;
  }
  .nav-items {
    gap: 2.5em;
  }
}

@media screen and (max-width: 1470px) {
  .nav-upper-items {
    gap: 6.2em;
  }
  .nav-items {
    gap: 2.2em;
  }
}

@media screen and (max-width: 1420px) {
  .nav-upper-items {
    gap: 6em;
  }
  .nav-items {
    gap: 2.2em;
  }
}

@media screen and (max-width: 1400px) {
  .nav-upper-items {
    gap: 5.3em;
  }
  .nav-items {
    gap: 2em;
  }
}

@media screen and (max-width: 1366px) {
  .nav-upper-items {
    gap: 3.9em;
  }
  .nav-items {
    gap: 2.5em;
  }
}

@media screen and (max-width: 1350px) {
  .nav-upper-items {
    gap: 3.3em;
  }
  .nav-items {
    gap: 2em;
  }
}

@media screen and (max-width: 1300px) {
  .nav-upper-items {
    gap: 2.5em;
  }
  .nav-items {
    gap: 1.7em;
  }
}

@media screen and (max-width: 1250px) {
  .nav-upper-items {
    gap: 2em;
  }
  .nav-items {
    gap: 1.3em;
  }
}

@media screen and (max-width: 1200px) {
  .nav-upper-items {
    gap: 1.2em;
  }
  .nav-items {
    gap: 0.9em;
  }
}

@media screen and (max-width: 1150px) {
  .nav-upper-items {
    gap: 0.5em;
  }
  .nav-items {
    gap: 0.7em;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b9b9b9;
  }
}

@media screen and (max-width: 1050px) {
  .nav-upper-items {
    gap: 0em;
  }
  .nav-items {
    gap: 0.2em;
  }
}

@media screen and (max-width: 600px) {
  .service-block-text {
    height: 70px;
  }

  .service-blocks {
    flex-direction: column;
  }
  .WhatsAppButton__root {
    bottom: 12% !important;
    right: 2% !important;
  }

  .WhatsAppButton__root img {
    width: 44px !important;
    height: 44px !important;
  }

  .WhatsAppButton__root .closePopUp {
    width: 12px !important;
    height: 12px !important;
  }

  .kalki-buy-font {
    font-size: 0.9em;
  }
  .nav {
    position: sticky;
    top: 0%;
    z-index: 5;
    background-color: #ffffff;
  }

  .store-heading {
    /* font-size: 1.4em; */
    margin-bottom: 1em;
  }

  .mobile-sidenavbar {
    height: 100vh;
    background-color: #004dac;
    color: #ffffff;
    position: fixed;
    top: 0%;
    z-index: 98;
    overflow-x: scroll;
    right: 0%;
    transition: 0.2s;
  }
  .navbar-open {
    width: 100%;
  }
  .navbar-off {
    width: 0%;
    padding: 0%;
  }
  .find-store-heading-container {
    display: flex;
    flex-direction: column;
    align-items: initial !important;
  }
  .find-store-modal {
    padding: 2rem;
  }
  /* .quiz-nav {
    display: none;
  } */
  .text-passion {
    font-family: "Passion One", cursive;
    font-size: 2em;
  }
  .wa-chat-btn-fixed-wrap {
    position: fixed;
    display: block;
    right: 2%;
    bottom: 5%;
  }

  .wa-chat-btn-icon-image {
    width: 50px;
  }

  .wa-chat-btn-fixed-wrap-mb {
    position: fixed;
    display: block;
    right: 2%;

    z-index: 5;
  }

  .call-wa-chat-btn-fixed-wrap-mb {
    position: fixed;
    display: block;
    z-index: 5;
    right: 1%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: rgb(0, 77, 172);
    font-size: 1.6em;
    display: flex;

    align-items: center;
    bottom: 13%;
  }

  .call-wa-chat-btn-fixed-wrap-mb {
    position: fixed;
    display: block;
    right: 2%;

    z-index: 5;
  }

  .test-ride-btn-fixed-wrap-mb {
    position: fixed;
    z-index: 5;
    bottom: 0% !important;
    left: 50%;
    text-align: center !important;
    transform: translateX(-50%);
    /* background-color: rgb(0,255,127); */
    background-color: rgb(0, 77, 172);
    color: rgb(0, 255, 127);
    font-size: 1em;
    /* color: #f0f1f5; */
    /* color: rgb(0,77,172); */
    outline: 0px;
    border: 0px;
    padding: 0.8em;
    display: none;
    font-size: 2.2vmax;
    font-weight: 800;
  }

  .test-ride-btn-fixed-wrap-mb:hover {
    color: #f0f1f5;
    background-color: #242424;
  }

  .test-ride-btn-fixed-wrap-mb.visible {
    display: flex;
  }
  /* Styling for Autocomplete search bar */
  #pac-card {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: Roboto;
    margin: 10px 0px 0px 0px;

    width: 100%;
    -moz-box-sizing: border-box;
    outline: none;
  }

  #pac-container {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 0px;
  }

  #store-container {
    padding: 10% 10% 10% 10%;
  }

  #map {
    height: 65vh;
    width: 100%;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    display: block;
    margin: auto;
    border: 1px solid grey;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    text-overflow: ellipsis;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  #title {
    color: #fff;

    background-color: #000000;
    font-size: 18px;
    font-weight: 400;
    padding: 15px;
  }

  .hidden {
    display: none;
  }
}
