.preloder {
  animation: fadeAway 0.5s ease-in-out 2s;
  -webkit-animation: fadeAway 0.5s ease-in-out 2s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.splide__pagination {
  position: static !important;
  margin-top: 12px !important;
}

.play-icon-container {
  position: absolute;
  top: 87% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.blogs-card {
  padding-top: 2em;
  padding-bottom: 1em;
}

.gif-content {
  font-size: 1em;
}

.heading-homepage {
  font-size: 2em;
}

.faq-heading {
  font-size: 1.2em;
}

/* .range-card-sub-div {
  display: flex;
  flex-direction: row;
  /* height: 400px; */
/* } */
/* .range-card-sub-div-content {
  max-width: 35%;
} */
.range-card-heading {
  font-size: 4em;
}

.desk-gif-1 {
  bottom: 5% !important;
  left: 3% !important;
}

.desk-gif-2 {
  bottom: 10% !important;
  left: 3% !important;
}

.desk-gif-3 {
  bottom: 10% !important;
  left: 3% !important;
}

.celebs-heading {
  position: absolute;
  top: 40%;
}

.em-hover {
  color: inherit;
}

.em-hover:hover {
  color: #004dac;
}

.em-btn {
  background-color: #004dac;
  color: #ffffff;
  padding: 0.5rem 1em;
  border-radius: 25px;
}

.em-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.know-btn {
  background-color: #cfcfcf;
  color: #000000;
  border-radius: 25px;
  padding: 0.5rem 1em;
}

.know-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.spec-box-div {
  width: 75%;
}

.spec-box-div {
  width: 75%;
}

.banner-video {
  width: 100%;
}

.specs-highlight h3 {
  font-size: 3em;
}

.specs-highlight p {
  font-size: 1.3em;
  color: #666664;
}

.specs-highlight button {
  font-size: 1.3em;
  background-color: #004dac;
}

.specs-highlight br {
  display: none;
}

@keyframes fadeAway {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -99;
  }
}

.slide-1-content h1 {
  font-size: 100px;
  color: white;
  text-shadow: 2px 2px 2px rgb(110, 108, 108);
  line-height: 80px;
  /* text-shadow: 5px 5px rgb(117, 113, 113); */
  font-weight: 600;
}
.slide-1-content p {
  font-size: 35px;
  margin-bottom: 0px;
  font-weight: 400;
  text-shadow: 2px 2px 2px rgb(110, 108, 108);

  color: white;
  margin-top: 0px;
}

.slide-1-content button {
  font-size: 15px;
  margin-top: 2.5em;
  color: #1d1d1d;
  background: #f8f4f1;
  border: none;
  font-weight: 600;
  border: none;
  padding: 0.9em 3.5em;
}

.range-card {
  position: relative;
  cursor: pointer;
}

.logo-img {
  width: 400px;
}

button.splide__arrow svg {
  width: 50%;
  fill: #ffffff;
}

button.splide__arrow svg:hover {
  fill: white !important;
}

.home-cta:hover {
  color: #242424;
  background-color: #f0f1f5;
}

.home-cta-1:hover {
  color: #ffffff;
  background-color: BLACK;
}

.home-cta-2:hover {
  background-color: #242424;
  color: #f0f1f5;
}

.landing-cta {
  color: #f0f1f5;
  background-color: #004dac;
}

.landing-cta:hover {
  color: #004dac;
  background-color: #f0f1f5;
}

.newlaunch-doodle-cta:hover {
  color: #f0f1f5;
  background-color: #242424;
}

.bike-cta {
  color: #f0f1f5;
  background-color: #004dac;
}

.bike-cta:hover {
  color: #f0f1f5;
  background-color: #1d1d1d;
}

.my-splide {
  padding-left: 30px;
  padding-right: 30px;
}

.my-splide .splide__track {
  padding: 1.5em 1em;
}

.home-main-slider .splide {
  padding: 0em !important;
}

.circle-carousel .splide {
  padding: 0em !important;
}

.splide__pagination__page {
  border-radius: 50% !important;
  height: 12px !important;
  width: 12px !important;
}

.splide__pagination__page.is-active {
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
  background: #004dac !important;
}

.splide__pagination__page:hover {
  background: #ccc !important;
}

.splide__arrows button {
  width: 30px;
  height: 30px;
  border: 3px solid #ffffff !important;
  border-radius: 50%;
}

.splide__arrow.splide__arrow--prev::before,
.splide__arrow.splide__arrow--next::before {
  color: white !important; /* Change this color to your desired color */
  /* Additional styling for the arrows if needed */
}

.home-cta-showstopper-btn {
  position: absolute;
  bottom: 34%;
  left: 13.5%;
}

.t-rex-family-button-banner {
  bottom: 17%;
}

.doodle-pro-button-banner-desktop {
  top: 68%;
  right: 7.5%;
}

.xcape-g1-button-banner-desktop {
  top: 75%;
  left: 3%;
}

.range-card:hover .overlay {
  height: 100%;
  width: 100%;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.527);
  position: absolute;
}

.headline {
  position: absolute;
  bottom: 0%;
  height: 20%;
  background: rgb(31, 34, 42);
  background: rgb(31, 34, 42);
  background: linear-gradient(
    180deg,
    rgba(31, 34, 42, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.headline p {
  font-size: 23px;
  color: white;
}
.range-arrow {
  width: 50px;

  opacity: 0;
}

.range-card:hover .range-arrow {
  width: 50px;
  opacity: 1;
  animation: movearrow 0.3s ease-in-out;
}

@keyframes movearrow {
  from {
    margin-right: 3em;
  }
  to {
    opacity: 1;
    margin-right: 0em;
  }
}

.best-seller img {
  scale: 1;
  transform: translate(-15%);
}
.best-seller-text {
  width: 80%;
}
.best-seller-text-subHeading {
  font-size: 25px;
  color: #bbbbbb;
  margin: 0px;
}

.best-seller-text h1 {
  font-size: 85px;
  color: #000000;
  font-weight: 900;
  margin-top: 0em;
}

.best-seller-text-desc {
  font-size: 18px;
}
.best-seller-text button {
  font-size: 15px;
  color: white;
  background-color: #000000;
  width: 200px;
  border: none;
  padding: 0.7em;
}

/* ...................... */

.bike-card {
  /* background-color: #f8f4f1; */

  padding-left: 1em;
  padding-right: 1em;
}

.bike-card h5 {
  font-weight: bold;
  font-size: 1.5em;
}
.the-bikes .bike-img-bg {
  /* aspect-ratio: 1/1; */

  align-items: center;
  /* width: 75%;
  height: 75%; */
}

.card-points p {
  font-size: 0.9em;
}

.card-price {
  display: inline-block;
  font-weight: 600;
  font-size: 1.3em;
  gap: 1em;
}
.card-price span {
  color: #7c7676b7;
  margin-left: 0.5em;
  font-weight: 550;
  font-size: 1.35em;
  text-decoration: line-through;
}
.save-percent {
  color: #004dac;
  padding: 0.3em;
}

/* .bike-card button {
  font-size: 0.9em;
  background-color: #000000;
  color: white;
  border: none;
  width: 100%;
  display: block;
  margin: auto;
  padding: 0.9em;
  border: none;
  box-shadow: 0px;
} */

/* ................ */

.accessories-section div {
  background-color: #000000;
}

.accessories-section h1 {
  color: white;
  font-size: 3.8em;
  font-weight: 900;
}

.accessories-section button {
  font-size: 1em;
  background-color: white;

  width: 60%;
  font-weight: 600;
  padding: 0.9em;
}

.testimonials {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #000000;
}
.testimonials-cards {
  margin-top: 5em;
}
.testimonials h1 {
  font-weight: 900;
  text-align: center;
  font-size: 3em;
  /* margin-bottom: 2em; */
  color: #fff;
}

.testimonials h5 {
  font-size: 1.5em;
  color: #fff;
  font-weight: 900;
}

.testimonials-desc {
  font-size: 0.9em;
  color: #d4cdcd;
  text-align: center;
}

.home-news h1 {
  font-size: 4em;
  font-weight: 900;
}
.home-news h2 {
  font-size: 2.5em;
}

.banner-img {
  height: 93vh;
  background-position: auto;
  background-size: auto 100%;

  background-position: center;
  background-repeat: no-repeat;
}

.home-banner-video-1 {
  width: 30%;
  z-index: 3;
  top: 10%;
  right: 13.3%;
}

.modal-video {
  height: 60%;
  width: 40%;
}

.home-banner-video-2 {
  width: 30%;
  z-index: 3;
  top: 60%;
  right: 13.3%;
}

.home-banner-cta-right {
  z-index: 3;
  top: 63%;
  right: 13.3%;
}
.new-banner-cta {
  z-index: 3;
  top: 70%;
  left: 72%;
  transform: translateX(-50%);
  /* transform: translate(-50%,-50%); */
}

.new-banner-festival-cta-flipkart {
  z-index: 3;
  top: 72%;
  left: 51.4%;
  transform: translateX(-50%);
}

.new-banner-festival-cta-amazon {
  z-index: 3;
  top: 72%;
  left: 71.7%;
  transform: translateX(-50%);
}

.new-banner-festival-line {
  z-index: 3;
  top: 83%;
  left: 62%;
  transform: translateX(-50%);
  color: white;
}

.new-banner-festival-cta-flipkart-mobile {
  z-index: 3;
  top: 43%;
  left: 5%;
}

.new-banner-festival-cta-amazon-mobile {
  z-index: 3;
  top: 43%;
  left: 65%;
}

.new-banner-cta-right-1 {
  z-index: 3;
  top: 65%;
  left: 68%;
  /* transform: translate(-50%,-50%); */
}

.new-banner-cta-right-video-desk {
  z-index: 3;
  top: 77%;
  right: 2%;
  /* transform: translate(-50%,-50%); */
}

.new-banner-cta-right-video-desk div {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
}

.kalki-home-banner-btn {
  top: 85%;
  left: 45%;
}
.new-banner-cta-right {
  z-index: 3;
  top: 82%;
  left: 47%;
  /* transform: translate(-50%,-50%); */
}
.event-button {
  z-index: 3;
  top: 74%;
  right: 32%;
}
.isic-banner {
  z-index: 3;
  top: 80%;
  right: 3%;
  translate: -50% -50%;
}
.home-banner-cta-left {
  z-index: 3;
  top: 58%;
  left: 17%;
  translate: -50% 50%;
}

/* ............. */

.range-hight-slider {
  height: 90vh;
}
.our-range img {
  width: 300px;
  transform: scale(1.7);

  z-index: 1;
}

.splide__slide.is-active img {
  /* transition: 200ms linear; */
  z-index: 50;
}

.splide__slide.is-active .font-facing {
  width: 300px;
  /* transform: scale(4.7); */
  display: block !important;
  /* transition: 300ms ease-in-out; */
  animation: big-bike 0.4s ease-in-out;

  animation-fill-mode: forwards;
  opacity: 1;
}
.splide__slide .silder-range-heading {
  top: -150%;
  font-size: 8em;
  position: absolute;
  opacity: 0;
  font-weight: 900;
  width: 100%;
  white-space: nowrap;
  text-align: center;

  color: #bbbbbb;
}

.splide__slide.is-active .silder-range-heading {
  font-size: 8em;
  opacity: 1;

  position: absolute;
  font-weight: 900;
  width: 100%;
  white-space: nowrap;
  text-align: center;

  color: #bbbbbb;
}

.home-cta {
  width: fit-content;
  background-color: #004dac;
  color: #f0f1f5;
  outline: 0px;
  border: 0px;
  font-size: 0.9em;
  padding: 0.7em 1.5em;
  border-radius: 25px;
}

.home-cta-1 {
  width: fit-content;
  background-color: #ffffff;
  color: black;
  outline: 0px;
  border: 0px;
  font-size: 0.9em;
  padding: 0.7em 1.5em;
  border-radius: 25px;
}

.home-cta-2 {
  background-color: #004dac;
  color: #f0f1f5;
  outline: 0px;
  border: 0px;
  font-size: 1.3em;
}

.landing-cta {
  background-color: #004dac;
  color: white;
  outline: 0px;
  border: 0px;
  font-size: 1.1em;
  padding: 0.4em 2.5em;
  border-radius: 25px;
}

.newlaunch-doodle-cta {
  background-color: #004dac;
  color: white;
  outline: 0px;
  border: 0px;
  font-size: 1.1em;
  padding: 0.4em 2.5em;
  border-radius: 25px;
}

.bike-cta {
  background-color: #004dac;
  color: white;
  outline: 0px;
  border: 0px;
  font-size: 1em;
  padding: 0.6em 1.5em;
  border-radius: 25px;
}

.btn-container {
  position: absolute;
  top: 55%;
  right: 6%;
  translate: translate(-50%, -50%);
  z-index: 2;
}

.btn-container-newlaunch {
  position: absolute;
  top: 55%;
  right: 12.1%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
}

.btn-container-productdiv {
  position: absolute;
  top: 109%;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
}

.range-cta {
  bottom: -105%;
  left: 50%;
  text-align: center;
  display: none;
  translate: -50%;
}
.splide__slide.is-active .range-cta {
  bottom: -105%;
  left: 50%;
  display: block;
  translate: -50%;
}
.news-card-lg:hover {
  transition: 200ms;
}

.news-card-lg {
  height: 550px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
}

.news-card-lg:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  transition: 200ms;
}

.voice-of-people {
  width: 100%;
  height: 1080px;
}

.find-store-btn {
  position: absolute;
  bottom: 45%;
  left: 90%;
  translate: -50% -50%;
}
.voice-of-people-thumbnail {
  border-radius: 5px;
}
@keyframes big-bike {
  to {
    transform: scale(3.2);
  }
}
.home-testride-btn {
  top: 72%;
  left: 6%;
}
/* ........................................................ */

@media only screen and (max-width: 1600px) {
  .new-banner-cta-right-video-desk {
    z-index: 3;
    top: 73%;
    right: 2%;
    /* transform: translate(-50%,-50%); */
  }
  .kalki-home-banner-btn {
    top: 85%;
    left: 43.5%;
  }
  .event-button {
    z-index: 3;
    top: 74%;
    right: 31%;
  }
  .our-range img {
    width: 250px;
    transform: scale(1.7);

    z-index: 1;
  }

  .splide__slide.is-active img {
    /* transition: 200ms linear; */
    z-index: 50;
  }

  .splide__slide.is-active .font-facing {
    width: 250px;
    /* transform: scale(4.7); */
    display: block !important;
    /* transition: 300ms ease-in-out; */
    animation: big-bike 0.4s ease-in-out;

    animation-fill-mode: forwards;
    opacity: 1;
  }
  .splide__slide .silder-range-heading {
    font-size: 8em;
    position: absolute;
    opacity: 0;
    font-weight: 900;
    width: 100%;
    white-space: nowrap;
    text-align: center;

    color: #bbbbbb;
  }

  .splide__slide.is-active .silder-range-heading {
    font-size: 8em;
    opacity: 1;

    position: absolute;
    font-weight: 900;
    width: 100%;
    white-space: nowrap;
    text-align: center;

    color: #bbbbbb;
  }

  .home-banner-cta-right {
    z-index: 3;
    top: 63%;
    right: 13.3%;
  }

  .isic-banner {
    z-index: 3;
    top: 52%;
    right: 1.8%;
    translate: -50% -50%;
  }

  .home-banner-cta-left {
    z-index: 3;
    top: 59%;
    left: 17.5%;
    translate: -50% 50%;
  }

  .range-cta {
    bottom: -105%;
    left: 50%;
    display: none;
    translate: -50%;
  }
  .splide__slide.is-active .range-cta {
    bottom: -105%;
    left: 50%;
    display: block;
    translate: -50%;
  }
  .find-store-btn {
    position: absolute;
    bottom: 30%;
    left: 88%;
    width: 180px;
    text-align: center;
    translate: -50% -50%;
  }
}

@media (max-width: 1400px) {
  .new-banner-cta-right-video-desk {
    z-index: 3;
    top: 70%;
    right: 2%;
    /* transform: translate(-50%,-50%); */
  }
}

@media (min-width: 641px) {
  .mobile-only {
    display: none;
  }
}

/* CSS for displaying content on screens 640px and below */
@media (max-width: 640px) {
  .t-rex-family-button-banner {
    bottom: 8%;
  }
  .doodle-pro-button-banner-mobile {
    bottom: 8%;
  }
  .question-content {
    font-size: 0.9em;
  }
  .kalki-home-banner-btn {
    bottom: 6%;
    left: 34.5%;
  }
  .mobile-only {
    display: block;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
  .grid-container {
    display: none;
  }
}
