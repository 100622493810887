.primary-background {
  background: linear-gradient(0deg, #cddcc5 0%, #108692 58%, #083d42 98%);
}

.secondary-background {
  background: linear-gradient(90deg, #cddcc5 0%, #3d939c 50%, #0a3f44 100%);
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet-active {
  background: #ffdc4f;
}

.dhoni-campaign-2 {
  .dhoni-animal-color {
    color: #ffdc4f;
  }
  .sub-heading {
    font-size: 24px;
  }
  .heading {
    font-size: 40px;
  }
  .usp-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .usp-heading {
    font-size: 18px;
  }
  .usp-text {
    font-size: 12.8px;
  }
  .dhoni-animal-border {
    position: relative;
    display: inline-block;
  }

  .dhoni-animal-border::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 4px;
    border-radius: inherit;
    background: linear-gradient(to bottom, #cddcc5, #108692);
    -webkit-mask: linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  .dhoni-animal-bg-primary {
    background: linear-gradient(0deg, #cddcc5 0%, #108692 58%, #083d42 98%);
  }
  .mode-card-text {
    font-size: 22px;
  }
  .bike-features {
    h4 {
      font-size: 1em;
    }
    p {
      font-size: 0.8em;
    }
  }
}

@media screen and (min-width: 800px) {
  .dhoni-campaign-2 {
    .secondSection-Text {
      position: absolute;
      bottom: 8%;
      left: 8%;
      li {
        width: 70%;
      }
    }
    .sub-heading {
      font-size: 48px;
    }
    .heading {
      font-size: 80px;
    }
    .usp-heading {
      font-size: 40px;
    }
    .usp-text {
      font-size: 24px;
    }
    .usp-card {
      flex-direction: row;
    }
    .mode-card-text {
      font-size: 32px;
    }
    .bike-features {
      h4 {
        font-size: 25px;
      }
      p {
        font-size: 17px;
      }
    }
  }
}
